.page--schedule {
	width: 100%;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 16px 0;

	&--header {
		position: relative;
		padding: 24px 16px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		&-title {
			padding: 12px;
			font-weight: bold;
			font-size: 48px;
			line-height: 58px;
			color: #FFFFFF;
			margin: 0;

			@media screen and (max-width: 1024px) {
				display: none;
			}
		}
	}

	&--blank {
		max-width: 600px;
		background-color: #ffffff;
		border-radius: 5px 5px 5px 5px;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@media screen and (max-width: 1024px) {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
		@media screen and (max-width: 600px) {
			height: 100%;
			padding-bottom: 55px;
		}
		@media screen and (max-width: 900px) {
			margin: 0;
			flex-direction: column;
			max-width: 100%;
		}
	}

	&--header {
		flex-shrink: 0;
		margin: 0 auto 0 auto;
		@media screen and (min-width: 900px) {
			margin: 0;
		}
	}

	&--header &--calendar {
		position: relative;
		background: transparent;
		max-width: 308px;

		.ant-select-selector {
			box-shadow: none !important;
		}

		.ant-picker-date-panel {
			.ant-picker-content {
				th {
					padding: 10px 0;
					color: #8A8A8F;
					font-weight: bold;
					font-size: 18px;
					line-height: 24px;
				}

				.ant-picker-cell {
					padding: 10px 0;
					font-weight: bold;
					font-size: 18px;
					line-height: 24px;
					width: 50%;
				}

				.ant-picker-cell-selected {
					background-color: #FFFFFF;

					.day, .workday {
						color: #00263E;
					}
				}
			}
		}

		.day, {
			color: #FFFFFF;
		}

		.weekend, .holiday {
			color: #DC4405;
		}

		.workday {
			color: #FFFFFF;

			&::after {
				content: "";
				position: absolute;
				top: 35px;
				right: calc(50% - 2.5px);
				width: 5px;
				height: 5px;
				border-radius: 50%;
				background: #FFFFFF;
				display: inline-block;
			}
		;
		}

		.ant-picker-panel {
			background: transparent;
			border: 0;

			.ant-picker-cell-selected {
				background-color: #FFFFFF;
				border-radius: 50%;
			}
		}

		&-year {
			.ant-select-selector {
				color: #FFFFFF;
				background: transparent;
				border: 0;
			}

			.ant-select-arrow svg path {
				fill: #FFFFFF;
			}
		}

		&-month {
			.ant-select-selector {
				background: transparent;
				border: 0;
				color: #FFFFFF;
				font-weight: bold;
				font-size: 32px;
				line-height: 39px;
				text-transform: capitalize;
			}

			.ant-select-arrow svg path {
				fill: #FFFFFF;
			}
		}

		&-year {
			.ant-select-selector {
				background: transparent;
				border: 0;
				color: #FFFFFF;
				font-weight: bold;
				font-size: 18px;
				line-height: 27px;
			}

			.ant-select-arrow svg path {
				fill: #FFFFFF;
			}
		}
	}

	&-container {
		width: 100%;
		max-width: 720px;
		position: relative;
		border-radius: 5px 5px 5px 5px;
		margin: 40px 25px 0 25px;
		padding-left: 100px;

		@media screen and (min-width: 1024px) {
			margin-top: 124px;
		}

		@media screen and (max-width: 1024px) {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			max-width: 100%;
		}
		@media screen and (min-height: 900px) {
			height: 100%;
			width: 100%;
		}
		@media screen and (max-width: 1280px) {
			padding-left: 0;
		}
		@media screen and (max-width: 900px) {
			padding: 0;
			margin: 0;
		}
	}

	p {
		margin-bottom: 2px;
	}

	&--container {
		height: 50%;
		width: 100%;
		margin: 0 20px;
	}

	.border-left {
		border-left: 5px solid #00263E;
	}

	&--shift {
		display: flex;
		justify-content: space-between;
		font-weight: bold;
		font-size: 18px;
		line-height: 27px;
		text-transform: uppercase;
		padding: 8px 16px 8px 8px;
		width: 100%;
		margin-right: 3px;
		/*Todo remove margin*/

		&-title {
			display: flex;
			justify-content: space-between;
			font-weight: normal;
			font-size: 16px;
			line-height: 22px;
			color: #00263E;
		}

		&-value {
			font-weight: bold;
			font-size: 16px;
			line-height: 22px;
		}
	}

	&--logo {
		position: absolute;
		right: 0;
		top: 40px;
		max-height: 230px;
		max-width: 559px;
		fill: #8A8A8F;
		@media screen and (max-width: 600px) {
			right: -200px;
			max-height: 165px;
			max-width: 354px;
		}
	}

	&--name {
		position: relative;
		z-index: 10;
		padding: 12px;
		margin: 0 0 24px 0;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 27px;
		color: #ffffff;
	}

	&--not-found {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #ffffff;
		padding: 60px;

		@media screen and (max-width: 600px) {
			margin: 0;
			max-width: 100%;
		}

		&--img {
			width: 120px;
			height: 120px;
			padding: 24px;
			margin-bottom: 32px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 30px;
			background-color: #F2F2F2;

			&--icon svg {
				height: 60px;
				width: 60px;

				path {
					fill: #8A8A8F;
				}
			}
		}

		&--text {
			color: #8A8A8F;
			font-size: 16px;
			line-height: 22px;
			text-align: center;
		}
	}
}
