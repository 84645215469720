#root {
	height: 100%;
}

body {
	margin: 0;
	overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
	font-family: "Helvetica Neue";

}

p, span, div {
	font-family: HelveticaNeueCyr;
}
