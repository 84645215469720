.page-question {
	width: 100%;
	align-items: flex-start;
	justify-content: flex-start;
	padding-top: 16px;

	&__container {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	&__header {
		position: relative;
		padding: 24px 16px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		@media screen and (max-width: 1024px) {
			max-width: 100%;
		}
	}

	&__blank {
		width: 100%;
		max-width: 600px;
		background-color: #ffffff;
		border-radius: 5px 5px 5px 5px;
		padding: 24px 16px;
		position: relative;

		@media screen and (max-width: 1024px) {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			max-width: 100%;
		}
	}

	&__name {
		position: relative;
		z-index: 10;
		color: #ffffff;
		font-style: normal;
		font-weight: bold;
		font-size: 32px;
		line-height: 1.2;
		margin: 0;

		@media screen and (min-width: 1024px) {
			font-size: 48px;
		}
	}

	&__logo {
		position: absolute;
		right: 0;
		top: 40px;
		max-height: 230px;
		max-width: 559px;
		fill: #8A8A8F;
		@media screen and (max-width: 600px) {
			right: -200px;
			max-height: 165px;
			max-width: 354px;
		}
	}
}
