.page {
	$this: &;
	display: flex;
	flex-direction: column;
	position: relative;

	&--container {
		padding: 16px 60px 60px;
		height: 100vh;
		position: relative;
		z-index: 1;

		@media screen and (max-width: 1024px) {
			padding: 8px 0 0;
		}

		@media screen and (max-width: 600px) {
			padding: 24px 0 0;
			height: calc(100vh - 80px);
		}
	}

	&--profile-container {
		position: relative;
		min-height: 70%;
		width: 100%;

		@media screen and (min-width: 1024px) {
			padding-bottom: 60px;
		}
	}

	&--payouts-container, &--inventory-container {
		position: relative;
		width: 100%;
		display: inline-flex;
		flex-direction: column;

		@media screen and (min-width: 1024px) {
			padding-bottom: 60px;
		}
	}


	&--header {
		padding: 24px 16px;
		display: flex;
		align-items: flex-end;
		min-height: 220px;
		height: 30%;
	}

	&--payouts--header, &--inventory--header {
		padding: 24px 16px;
		display: flex;
		align-items: flex-end;
	}

	&--user {
		background-color: #00263E;

		#{$this}--body {
			display: flex;

			@media screen and (min-width: 1023px) {
				padding-bottom: 120px;
				align-items: safe center;
			}
		}
	}
}

#salary-container {
	@media screen and (min-width: 600px) {
		display: flex;
	}
}

#schedule-container {
	@media screen and (min-width: 600px) {
		display: flex;
	}
	@media screen and (max-width: 900px) {
		display: flex;
		flex-direction: column;
	}
}

#question-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.border--bottom {
	border-bottom: 1px solid #DCDDE0;
	padding-bottom: 16px;
}

.darkened {
	background-color: #F2F2F2;
}
