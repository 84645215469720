.question {
	display: flex;
	flex-direction: column;

	@media screen and (max-width: 1024px) {
		min-height: calc(100vh - 158px);
	}

	@media screen and (max-width: 600px) {
		min-height: calc(100vh - 158px);
	}

	&__content {
		flex-grow: 1;
	}

	&__label {
		font-size: 14px;
		color: #8A8A8F;
		margin-bottom: 16px;
	}

	&__text {
		font-size: 16px;
		color: #000000;
		margin-bottom: 24px;
	}

	&__answers {
		&.ant-radio-group {
			display: flex;
			flex-direction: column;
			width: 100%;
		}
	}

	&__answer {
		&.ant-radio-button-wrapper {
			display: block;
			border-top: none !important;
			border-left: none !important;
			border-right: none !important;
			border-bottom: 1px solid #DCDDE0;
			font-size: 16px;
			line-height: 1.3;
			color: #00263E;
			padding: 18px 30px 24px 0;
			border-radius: 0 !important;
			height: auto;
			outline: none !important;
			box-shadow: none !important;

			&::after {
				content: '';
				display: none;
				position: absolute;
				top: 20px;
				right: 0;
				width: 15px;
				height: 15px;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				background-image: url("data:image/svg+xml,%3Csvg width='15' height='12' viewBox='0 0 15 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.699 0.636353L14.2727 2.23544L5.09447 11.5454L0.636292 7.01499L2.20993 5.4159L5.09447 8.34772L12.699 0.636353Z' fill='%23DC4405'/%3E%3C/svg%3E%0A");
			}

			&.ant-radio-button-wrapper-checked {
				color: #DC4405;

				&::after {
					display: block;
				}
			}

			&::before {
				display: none !important;
			}
		}
	}

	&__button {
		flex-shrink: 0;
	}

	&__image {
		width: 120px;
		height: 120px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 30px;
		background-color: #F2F2F2;
		margin-bottom: 32px;

		svg {
			height: 60px;
			width: 60px;

			path {
				fill: #8A8A8F;
			}
		}
	}

	&__desc {
		font-size: 14px;
		color: #8A8A8F;
		text-align: center;
		max-width: 273px;
	}

	&--empty {
		justify-content: center;
		align-items: center;
	}
}
