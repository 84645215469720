.page--salary {
	width: 100%;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 16px 0;

	&--header {
		position: relative;
		width: 50%;
		padding: 24px 16px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		text-align: center;

		@media screen and (max-width: 600px) {
			width: 100%;
		}

		&--title {
			width: 50%;
			display: flex;
			align-items: center;

			@media screen and (min-height: 800px) {
				height: 10%;
			}

			&-text {
				font-weight: bold;
				font-size: 18px;
				line-height: 27px;
				text-align: center;
				color: #FFFFFF;
				padding-left: 20px;
			}
		}

		&--back {
			line-height: 18px;
			font-size: 18px;

			svg path {
				fill: #FFFFFF;

			}
		}

		&--payout {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 32px 0;
			text-align: start;

			&-title {
				font-weight: bold;
				font-size: 18px;
				line-height: 27px;
				color: #FFFFFF;
			}

			&-amount {
				font-style: normal;
				font-weight: bold;
				font-size: 44px;
				line-height: 53px;
				color: #FFFFFF;
			}

			p {
				margin-bottom: 2px !important;
			}
		}

		&--payments {
			width: 100%;
			display: flex;

			&-salary {
				display: inline-flex;
				flex-direction: column;
				border-left: 2px solid #FFFFFF;
				color: #FFFFFF;
				padding-left: 16px;
				text-align: start;
				width: 50%;

				&-month {
					font-size: 14px;
					line-height: 20px;
				}

				&-amount {
					font-size: 18px;
					font-weight: bold;
					line-height: 27px;
					text-transform: uppercase;
					width: max-content;
				}

				&-title {
					font-size: 14px;
					line-height: 20px;
				}
			}
		}
	}

	&--result {
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding: 8px 16px 8px 12px;
		border-left: 5px solid #00263E;
		font-weight: bold;
		font-size: 18px;
		line-height: 27px;
		text-transform: uppercase;
		color: #000000;
	}

	&--accruals {
		width: 100%;
		padding: 8px 16px 8px 12px;
		border-left: 5px solid #00C489;
		font-weight: bold;
		font-size: 18px;
		line-height: 27px;
		text-transform: uppercase;
		color: #000000;

		&-name {
			display: flex;
			justify-content: space-between;
			font-size: 16px;
			line-height: 22px;
			color: #000000;
		}

		&-sum {
			font-weight: bold;
			font-size: 16px;
			line-height: 22px;
			color: #000000;
		}

		&-period {
			font-size: 14px;
			line-height: 20px;
			color: #8A8A8F;
		}
	}

	&--deductions {
		width: 100%;
		padding: 8px 16px 8px 12px;
		border-left: 5px solid #FF3B30;
		font-weight: bold;
		font-size: 18px;
		line-height: 27px;
		text-transform: uppercase;
		color: #000000;
	}

	&-container {
		width: 50%;
		position: relative;
		min-height: 75%;
		margin: 95px 24px 0 24px;

		@media screen and (max-width: 1024px) {
			margin: 95px 24px 0 0;
		}
		@media screen and (max-width: 600px) {
			width: 100%;
			height: 65%;
			margin: 0;
		}
		@media screen and (max-height: 500px) {
			height: 100%;
		}
	}

	p {
		margin-bottom: 2px;
	}

	&--container {
		height: 75%;
		width: 100%;
		margin: 0 20px;
	}

	&--blank {
		max-width: 720px;
		background-color: #ffffff;
		border-radius: 5px 5px 5px 5px;

		@media screen and (max-width: 1024px) {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}

		@media screen and (max-width: 600px) {
			padding-bottom: 55px;
		}
	}

	&--logo {
		position: absolute;
		right: 0;
		top: 40px;
		max-height: 230px;
		max-width: 559px;
		fill: #8A8A8F;
		@media screen and (max-width: 600px) {
			right: -200px;
			max-height: 165px;
			max-width: 354px;
		}
	}

	&--name {
		position: relative;
		z-index: 10;
		color: #ffffff;

		font-style: normal;
		font-weight: bold;
		font-size: 32px;
		line-height: 39px;
		margin: 0;
	}
}
