.button {
	$this: &;
	width: 100%;
	padding: 15px !important;
	font-size: 16px;
	line-height: 16px !important;
	letter-spacing: 0.05em;
	font-weight: bold !important;
	text-transform: uppercase;
	height: initial !important;
	background-color: #DC4405;
	color: #fff;

	&--trans {
		background-color: transparent;
		color: #DC4405;
	}

	&[disabled] {
		background-color: #DCDDE0 !important;
		border-color: #DCDDE0 !important;
		color: #fff !important;
	}
}
