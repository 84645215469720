.layout {
	overflow-x: hidden;
	$this: &;
	display: flex;
	flex-direction: column;
	position: relative;
	min-height: 100%;

	&--body {
		flex-grow: 1;
		background-color: #fff;
		border-radius: 5px 5px 0 0;
		padding: 24px 16px;
		position: relative;
	}

	&--empty {
		background-color: #00263E;

		#{$this}--body {
			display: flex;

			@media screen and (min-width: 1023px) {
				padding-bottom: 120px;
				align-items: safe center;
			}
		}
	}

	&--user {
		background-color: #00263E;

		.header {
			padding-bottom: 320px;
		}
	}

	&--progress {
		position: absolute;
		left: 0;
		top: 0;
		height: 2px;
		background-color: #fff;
		width: 100%;
		overflow: hidden;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 20%;
			height: 100%;
			transform: translateX(-50%);
			border-radius: 25%;
			background-color: $color-accent;
			animation: slide 1s alternate-reverse infinite;
		}
	}
}

.ant-layout-sider-trigger {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	color: #00263E !important;
	background: #ffffff !important;
	letter-spacing: 0.2px;
	display: flex;
	align-items: center;
	padding-left: 33px;

	.ant-layout-sider-collapsed & {
		padding-left: 0;
		justify-content: center;
	}

	i {
		display: block;
		font-size: 30px;
		line-height: 30px;
		font-style: normal;
		font-family: monospace;
		position: relative;
		top: -4px;
	}

	span {
		display: block;
		margin-left: 24px;
	}
}

.ant-layout {
	height: 100%;
}

@keyframes slide {
	0% {
		left: 0
	}

	100% {
		left: 100%;
	}
}
