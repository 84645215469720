.ant-notification {
	&.ant-notification-bottomRight {
		margin-right: 16px !important;
		bottom: 0 !important;

		@media screen and (max-width: 600px) {
			bottom: 55px !important;
		}
	}
}

.ant-notification-notice {
	background: #323232;
	border-radius: 8px;
	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
	max-width: initial;

	@media screen and (max-width: 600px) {
		width: calc(100vw - 32px) !important;
	}

	&.ant-notification-notice-error {
		background: #DC4405;
	}
}

.ant-notification-notice-icon {
	display: none !important;
}

.ant-notification-notice-message {
	color: #fff !important;
	margin-left: 0 !important;
	font-size: 14px;
	font-weight: normal;
}

.ant-notification-notice-close {
	color: #fff !important;

	svg {
		path {
			fill: #fff !important;
		}
	}
}
