.form-item, .ReactInputVerificationCode__item, .css-jxah1j {
	$this: &;

	font-size: 16px !important;
	line-height: 22px !important;
	padding: 11px 0 !important;
	border: none !important;
	border-radius: 0 !important;
	box-shadow: none !important;
	border-bottom: 1px solid $color-accent !important;
	caret-color: $color-accent;

	&--empty {
		border-color: $color-secondary !important;

		&:focus {
			border-color: $color-secondary !important;
		}

		::placeholder {
			color: $color-secondary;
		}
	}

	input {
		font-size: 16px !important;
		line-height: 22px !important;
	}

	&--text {
		.ant-input-prefix {
			margin-right: 0;
		}
	}

	&--digit {
		text-align: center;
		max-width: 45px;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none !important;
			margin: 0 !important;
		}

		&[type=number] {
			-moz-appearance: textfield !important;
		}
	}

	&.has-error {
		border-color: $color-red !important;
	}
}

#one-time-code {
	width: 0;
	height: 0;
}

.ReactInputVerificationCode__item {
	cursor: pointer;

	&.is-active.css-jxah1j {
		border-bottom: 3px solid $color-secondary !important;
	}
}

.form-item-wrapper {
	&--digits {
		display: flex;
		align-items: safe center;
		justify-content: center;
	}
}

.form-item-error {
	font-size: 14px;
	line-height: 20px;
	color: $color-red;
	padding: 5px 0;
}

.css-jxah1j {
	width: 45px !important;
	height: 45px !important;
	font-size: 16px;
	line-height: 22px;
	color: #8A8A8F;
}

.errors {
	margin-top: 10px;
	font-size: 14px;
	line-height: 20px;
	color: #FF3B30;
	padding: 4px;
}
