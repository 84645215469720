.page--inventory {
	width: 100%;
	align-items: flex-start;
	justify-content: flex-start;
	min-height: 100px;
	padding: 10px 0;

	&--header {
		position: relative;

		&--payout {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: start;

			&-title {
				font-weight: bold;
				font-size: 18px;
				line-height: 27px;
				color: #FFFFFF;
			}

			&-amount {
				font-style: normal;
				font-weight: bold;
				font-size: 44px;
				line-height: 53px;
				color: #FFFFFF;
			}

			p {
				margin-bottom: 2px !important;
			}
		}
	}

	p {
		margin-bottom: 2px;
	}

	&--container {
		width: 100%;
		margin: 0 20px;

		button {
			width: 200px;
			text-align: start;
		}
	}

	&--blank {
		max-width: 600px;
		background-color: #ffffff;
		border-radius: 5px 5px 5px 5px;

		@media screen and (max-width: 1024px) {
			max-width: 100%;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			height: calc(100% - 151px);
		}

		@media screen and (max-width: 600px) {
			padding-bottom: 55px;
		}
	}

	&--not-found {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #ffffff;
		padding: 60px;
		height: 100%;

		@media screen and (max-width: 600px) {
			margin: 0;
			max-width: 100%;
		}

		&--img {
			width: 120px;
			height: 120px;
			padding: 24px;
			margin-bottom: 32px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 30px;
			background-color: #F2F2F2;

			&--icon svg {
				height: 60px;
				width: 60px;

				path {
					fill: #8A8A8F;
				}
			}
		}

		&--text {
			color: #8A8A8F;
			font-size: 16px;
			line-height: 22px;
			text-align: center;
		}
	}

	&--period {

		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
	}

	&--price, &--number {
		&-title {
			color: #8A8A8F;
			font-size: 14px;
			line-height: 20px;
		}

		&-value {
			color: #000000;
			font-weight: 500;
		}

		&-number {
			color: #8A8A8F;
		}

		&-sum {
			font-weight: 700;
			color: #000000;
		}
	}

	&--number {
		display: flex;
		justify-content: space-between;
	}

	&--logo {
		position: absolute;
		right: 0;
		top: 40px;
		max-height: 230px;
		max-width: 559px;
		fill: #8A8A8F;
		@media screen and (max-width: 600px) {
			right: -200px;
			max-height: 165px;
			max-width: 354px;
		}
	}

	&--name {
		position: relative;
		z-index: 10;
		color: #ffffff;

		font-style: normal;
		font-weight: bold;
		font-size: 32px;
		line-height: 39px;
		margin: 0;
	}

}
