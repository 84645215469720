.page--profile {
	width: 100%;
	align-items: flex-start;
	justify-content: flex-start;
	padding-top: 16px;

	p {
		margin-bottom: 2px;
	}

	&--container {
		width: 100%;
		margin: 0 20px;

		button {
			width: 200px;
			text-align: start;
		}
	}

	&--header {
		max-width: 600px;
		position: relative;
		padding: 24px 16px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		@media screen and (max-width: 1024px) {
			max-width: 100%;
		}
	}

	&--blank {
		max-width: 600px;
		height: 100%;
		background-color: #ffffff;
		border-radius: 5px 5px 5px 5px;

		@media screen and (max-width: 1024px) {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			max-width: 100%;
		}

		@media screen and (max-width: 600px) {
			padding-bottom: 55px;
		}
	}

	&--position {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;

		&--name {
			border-left: 5px solid #00263E;
			width: 100%;
			padding: 9px 16px;
			font-size: 18px;
			font-weight: 700;
			line-height: 27px;
			text-transform: uppercase;
		}
	}

	&--company {
		color: #8A8A8F;
		font-size: 14px;
		line-height: 20px;
	}

	&--company span {
		color: #000000;
	}

	&--department {
		color: #8A8A8F;
		font-size: 14px;
		line-height: 20px;
	}

	&--department span {
		color: #000000;
	}

	&--tariff {
		display: flex;
		justify-content: space-between;
		font-size: 16px;
		line-height: 22px;
	}

	&--logo {
		position: absolute;
		right: 0;
		top: 40px;
		max-height: 230px;
		max-width: 559px;
		fill: #8A8A8F;
		@media screen and (max-width: 600px) {
			right: -200px;
			max-height: 165px;
			max-width: 354px;
		}
	}

	&--select-company {
		.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
			border: 0;
			color: #00263E;
			box-shadow: none;
		}

		.ant-select-open > .ant-select-arrow {
			transform: rotate(180deg);
		}

		.ant-select {
			width: 100%;
			font-size: 16px;
			line-height: 22px;
			color: #00263E;

			svg path {
				fill: #00263E;
			}


			.ant-select-selector {
				border: 0;
			}
		}
	}

	&--name {
		position: relative;
		z-index: 10;
		color: #ffffff;
		font-style: normal;
		font-weight: bold;
		font-size: 32px;
		line-height: 1.2;
		margin: 0;

		@media screen and (min-width: 1024px) {
			font-size: 48px;
		}
	}

	&--logout {
		width: 50%;
		padding-left: 0 !important;
		background-color: transparent !important;
		color: #DC4405 !important;
		font-weight: bold;
		font-size: 16px;
		line-height: 22px;

		letter-spacing: 0.05em;
		text-transform: uppercase;
	}
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled), .ant-select-item-option {
	background-color: #FFFFFF;
}

.ant-select-item:not(.ant-select-item-option-selected) {
	span > svg {
		display: none;
	}
}

.ant-select-item-option-active,
.ant-select-item-option-selected,
.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-select-item-option-active span > svg > path,
.ant-select-item-option-selected span > svg > path {
	fill: #dc4405;
	color: #dc4405;
}
