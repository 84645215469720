.error-block {
	padding: 60px;

	&__title {
		color: #fff;
		font-size: 100px;
		font-weight: bold;

		@media screen and (min-width: 1280px) {
			font-size: 200px;
		}
	}

	&__text {
		color: #ffffff;
		font-weight: bold;
		font-size: 32px;
	}
}
