.page--salaries {
	width: 100%;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 16px 0;
	cursor: pointer;

	&--header {
		position: relative;
		max-width: 600px;
		padding: 24px 16px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	&--year {
		.ant-select:not(.ant-select-customize-input) .ant-select-selector {
			background-color: transparent;
			border: 0;
			color: #fff;
			font-weight: bold;
			font-size: 24px;
			line-height: 27px;
			fill: #fff;
		}

		.ant-select-arrow svg path {
			fill: #FFFFFF;
		}

		.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
			box-shadow: 0 0 0 0;
		}

		&-period {
			font-weight: bold;
			font-size: 24px;
			line-height: 27px;
			color: #8A8A8F;
			margin-right: 24px;
		}

		&-select {

		}
	}

	&-container {
		position: relative;
		height: 75%;

		@media screen and (min-height: 900px) {
			width: 70%;
			min-width: 400px;
		}
	}

	p {
		margin-bottom: 2px;
	}

	&--container {
		height: 75%;
		width: 100%;
		margin: 0 20px;
	}

	&--blank {
		height: 100%;
		max-width: 600px;
		background-color: #ffffff;
		border-radius: 5px 5px 5px 5px;
		overflow-x: hidden;

		@media screen and (max-width: 1024px) {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			max-width: 100%;
		}
		@media screen and (max-width: 1000px) and (orientation: landscape) {
			max-width: 100%;
		}
		@media screen and (max-width: 600px) {
			padding-bottom: 55px;
		}
	}


	&--month {
		display: flex;
		justify-content: space-between;

		&-title {
			font-size: 18px;
			font-weight: 700;
			line-height: 27px;
			text-transform: uppercase;
			color: #00263E;
		}

		&-value {
			font-weight: bold;
			font-size: 16px;
			line-height: 22px;
		}
	}

	&--logo {
		position: absolute;
		right: 0;
		top: 40px;
		max-height: 230px;
		max-width: 559px;
		fill: #8A8A8F;
		@media screen and (max-width: 600px) {
			right: -200px;
			max-height: 165px;
			max-width: 354px;
		}
	}

	&--name {
		position: relative;
		z-index: 10;
		color: #ffffff;
		font-style: normal;
		font-weight: bold;
		font-size: 32px;
		line-height: 1.2;

		@media screen and (min-width: 1024px) {
			font-size: 48px;
		}
	}

	&--not-found {
		max-width: 600px;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 75%;
		background-color: #ffffff;
		border-radius: 5px 5px 5px 5px;

		@media screen and (max-width: 1024px) {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
		@media screen and (max-width: 1000px) and (orientation: landscape) {
			max-width: 100%;
		}
		@media screen and (max-width: 600px) {
			width: 100%;
			margin: 0;
			height: 75%;
		}

		&--img {
			width: 120px;
			height: 120px;
			padding: 24px;
			margin-bottom: 32px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 30px;
			background-color: #F2F2F2;

			&--icon svg {
				height: 60px;
				width: 60px;

				path {
					fill: #8A8A8F;
				}
			}
		}

		&--text {
			color: #8A8A8F;
			font-size: 16px;
			line-height: 22px;
			text-align: center;
		}
	}

	&--tabs {
		.ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > .ant-tabs-nav::before {
			border: 0 !important;
		}

		.ant-tabs-tab {
			color: #DCDDE0;
			font-weight: bold;
			font-size: 18px;
			line-height: 27px;
			background-color: transparent;
			border: 0;
			text-transform: uppercase;
			border-bottom: 2px solid #DCDDE0;
		}

		.ant-tabs-top > .ant-tabs-nav {
			margin-bottom: 0;
		}

		.ant-tabs-tab-active {
			color: #ffffff;
			border-top: 0;
		}

		.react-tabs__tab:hover {
			color: #ffffff;
		}
	}

}
