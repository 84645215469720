.codes {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	&__field {
		margin-bottom: 0;

		.pincode-input-container {
			display: flex;
			margin: 0 -12px !important;
		}

		.pincode-input-text {
			height: 45px !important;
			width: 45px !important;
			margin: 0 12px !important;
			border: none !important;
			border-bottom: 1px solid #8A8A8F !important;
			transition: border-color 0.3s !important;
			font-size: 16px !important;
			color: #000000 !important;

			&:hover,
			&:focus {
				border-color: #DC4405 !important;
			}
		}
	}

	&__errors {
		width: 100%;
		text-align: center;
	}
}
