.splesh {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 10000;
	opacity: 1;
	transition: opacity 0.4s;

	&__bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		background-color: #00263E;
		background-repeat: no-repeat;
		background-position: 0 0;
		background-size: auto 110%;

		@media screen and (min-width: 1024px) {
			background-size: cover;
		}
	}

	&__container {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#00263E, 0.75);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 2;
	}

	&__logo {
		width: 160px;
		height: 97px;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&.is-hide {
		opacity: 0;
	}
}
