.header {
	background-color: transparent;
	height: 81px;

	&--menu {
		position: fixed;
		z-index: 10;
		bottom: 0;
		width: 100%;
		display: flex;
		justify-content: space-between;
		box-shadow: 0px -0.5px 0px rgba(0, 0, 0, 0.3);
		border-top: 1px solid #b0afaf;
		padding: 8px 0;

		@media (max-width: 722px) {
			bottom: env(safe-area-inset-bottom, 0);
		}

		@media (min-width: 723px) {
			position: relative;
			width: 100%;
			height: 80px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		&--link-text {
			display: flex;
			flex-direction: column;
			font-size: 10px;
			font-weight: 700;
			line-height: 14px;
			margin-left: 0 !important;
			color: #00263E;
		}

		#mobile-header &--link-icon {
			font-size: 20px;
			height: 20px;
		}

		&--item {
			text-align: center;
			max-width: 5.6em;
			padding: 0 0.5em !important;
			line-height: 24px;
		}
	}

	&--logo {
		max-width: 85px;
		max-height: 33px;
	}

	&--back {
		cursor: pointer;

		svg {
			width: 18px;
			height: 18px;

			& > path {
				fill: #fff;
			}
		}
	}
}

.desktop__header {
	background-color: transparent;

	&--logo {
		height: 144px !important;
		text-align: center;
		padding: 0 !important;
		margin: 0 !important;

		.ant-layout-sider-collapsed & {
			@media screen and (max-width: 1024px) {
				display: none;
			}
		}

		.ant-menu-title-content {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		img {
			width: 192px;
			margin: auto;
			padding: 24px 24px;
			filter: invert(88%) sepia(13%) saturate(6253%) hue-rotate(174deg) brightness(95%) contrast(102%);
		}

		&--text {
			height: 20px;
			line-height: 20px;
			color: #00263E;
		}
	}

	&--menu {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		box-shadow: 0px -0.5px 0px rgba(0, 0, 0, 0.3);
		border-top: 1px solid #b0afaf;
		padding: 8px 0 32px 0;

		&--link-text {
			font-size: 16px;
			font-weight: 400;
			line-height: 16px;
			margin-left: 0 !important;
			color: #00263E;

			.ant-layout-sider-collapsed & {
				display: block;
				font-size: 10px;
				font-weight: bold;
				letter-spacing: 0.012em;
				max-width: 100%;
				margin-top: 30px;
			}
		}

		&--link-icon {
			width: 32px !important;

			svg {
				font-size: 16px;
				font-weight: 700;
				margin-right: 20px;
				width: 16px;
			}
		}

		&--item {
			text-align: center;
			min-width: 240px;
			height: 56px !important;
			max-width: 5.6em;
			padding: 0 30px !important;
			line-height: 32px;
			font-weight: 400;
			border-left: 3px solid transparent;
			position: relative;

			.ant-layout-sider-collapsed & {
				padding: 0 calc(50% - 16px / 2) !important;
			}

			.ant-menu-title-content {
				display: flex;
				align-items: center;
				margin-left: 24px !important;

				.ant-layout-sider-collapsed & {
					opacity: 1 !important;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					margin-left: 0 !important;
					text-align: center;
				}
			}
		}
	}
}

.ant-menu-inline-collapsed {
	.desktop__header--menu--item {
		min-width: 10px;
	}

	.ant-menu-title-content {
		span {
			display: none;
		}

		img {
			padding: 24px 4px;
			width: 192px;
		}
	}
}

.landscape {
	height: 100vh;
}

.ant-layout-sider-trigger {
	background-color: #FFFFFF;

	.anticon > svg > path {
		fill: #00263E;
	}
}

.ant-menu-horizontal > .ant-menu-item::after, .ant-menu-horizontal > .ant-menu-submenu::after {
	display: none;
}

.ant-menu::before, .ant-menu::after {
	display: none;
}

.ant-menu-inline .ant-menu-item::after {
	border-right: 0 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background: #F7F8FC;
}

#mobile-desktop__header .ant-menu-item-selected {
	border-left: 3px solid #DC4405 !important;
}

.ant-menu-item-selected svg > path {
	fill: #dc4405;
}

svg > path {
	fill: #000000;
}

.ant-layout-sider-children {
	width: 100%;
}

.ant-menu-item-icon {
	width: 20px !important;
}
