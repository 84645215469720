.page--payouts {
	width: 100%;
	align-items: flex-start;
	justify-content: flex-start;
	min-height: 100px;
	padding: 10px 0;

	p {
		margin-bottom: 2px;
	}

	&--container {
		width: 100%;
		margin: 0 20px;

		button {
			width: 200px;
			text-align: start;
		}
	}

	&--blank {
		max-width: 600px;
		height: 100%;
		background-color: #ffffff;
		border-radius: 5px 5px 5px 5px;

		@media screen and (max-width: 1024px) {
			max-width: 100%;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
		@media screen and (max-width: 900px) and (orientation: landscape) {
			max-width: 100%;
			width: 100%;
			margin: 0;
			height: 75%;
		}

		@media screen and (max-width: 600px) {
			padding-bottom: 55px;
		}
	}

	&--not-found {
		max-width: 600px;
		width: 50%;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 80%;
		background-color: #ffffff;
		border-radius: 5px 5px 5px 5px;

		@media screen and (max-width: 1024px) {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
		@media screen and (max-width: 600px) {
			width: 100%;
			margin: 0;
			height: 75%;
		}

		&--img {
			width: 120px;
			height: 120px;
			padding: 24px;
			margin-bottom: 32px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 30px;
			background-color: #F2F2F2;

			&--icon svg {
				height: 60px;
				width: 60px;

				path {
					fill: #8A8A8F;
				}
			}
		}

		&--text {
			color: #8A8A8F;
			font-size: 16px;
			line-height: 22px;
			text-align: center;
		}
	}

	&--period {

		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
	}

	&--advance {
		display: flex;
		justify-content: space-between;

		&-title {
			color: #8A8A8F;
			font-size: 14px;
			line-height: 20px;
		}

		&-value {
			font-weight: bold;
			font-size: 16px;
			line-height: 22px;
		}
	}

	&--logo {
		position: absolute;
		right: 0;
		top: 40px;
		max-height: 230px;
		max-width: 559px;
		fill: #8A8A8F;
		@media screen and (max-width: 600px) {
			right: -200px;
			max-height: 165px;
			max-width: 354px;
		}
	}

	&--name {
		position: relative;
		z-index: 10;
		color: #ffffff;
		font-style: normal;
		font-weight: bold;
		font-size: 32px;
		line-height: 1.2;
		margin: 0;

		@media screen and (min-width: 1024px) {
			font-size: 48px;
		}
	}

}
